import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../App';

import css from './navigation.module.scss';

export const Navigation = () => {
    const { t } = useTranslation('common');

    const [checked, setChecked] = React.useState(true);

    const classes = classNames(
        isMobileOnly && css.navigationMobile,
        isPortraitTablet && css.navigationMobile,
        !isMobileOnly && !isPortraitTablet && css.navigationDesktop,
    );

    return (
        <>
            <nav className={css.root}>
                {(isMobileOnly || isPortraitTablet) && (
                    <>
                        <input
                            checked={!checked}
                            className={css.checkbox}
                            id="toggle"
                            type="checkbox"
                            readOnly
                        />
                        <label
                            onClick={() => {
                                setChecked(old => !old);
                            }}
                            htmlFor="toggle"
                            className={css.hamburger}
                        />
                    </>
                )}

                <div className={classes}>
                    <NavLink
                        exact={true}
                        activeClassName={css.active}
                        className={css.link}
                        to="/"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        {t('navigation.home')}
                    </NavLink>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/party-info-location"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        {t('navigation.party')}
                    </NavLink>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/agenda"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        {t('navigation.agenda')}
                    </NavLink>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/dresscode"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        {t('navigation.dresscode')}
                    </NavLink>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/community"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        {t('navigation.community')}
                    </NavLink>
                    <NavLink
                        activeClassName={css.active}
                        className={css.link}
                        to="/contact"
                        onClick={() => {
                            setChecked(old => !old);
                        }}
                    >
                        {t('navigation.contact')}
                    </NavLink>
                </div>
            </nav>
        </>
    );
};
