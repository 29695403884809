import React from 'react';
import { Helmet } from 'react-helmet';
import { Title } from '../../common/elements/title/title';
import { useTranslation } from 'react-i18next';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './disclaimer.module.scss';

import disclaimer1 from '../../common/pics/ver-7.jpg';

export const Disclaimer: React.FC = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>Disclaimer - LADZ</title>
                <meta
                    name="description"
                    content="The disclaimer of LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>

            <section className={css.root}>
                <ContentBlock size="medium">
                    <Title hasMargin as="h1" text={t('disclaimer.title')} />
                    <Title
                        subHeader
                        as="h2"
                        text={t('disclaimer.copyrightTitle')}
                    />

                    <Paragraph>{t('disclaimer.copyrightText')}</Paragraph>

                    <Title
                        subHeader
                        as="h2"
                        text={t('disclaimer.liabilityTitle')}
                    />
                    <Paragraph>{t('disclaimer.liabilityText')}</Paragraph>

                    <Title
                        subHeader
                        as="h2"
                        text={t('disclaimer.linksTitle')}
                    />
                    <Paragraph>{t('disclaimer.linksText')}</Paragraph>
                </ContentBlock>
                <ImageBlock posY="80%" size="small" src={disclaimer1} />
            </section>
        </>
    );
};
