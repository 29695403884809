import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../common/elements/external-link/external-link';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from '../party-info-location/party-info-location.module.scss';

import community1 from '../../common/pics/ver-5.jpg';
import community2 from '../../common/pics/ver-6.jpg';

export const Community: React.FC = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>Community - LADZ</title>
                <meta
                    name="description"
                    content="The community of LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>

            <section className={`${css.root} ${css.border}`}>
                <ImageBlock posY="73%" size="small" src={community1} />
                <ContentBlock size="medium" border={false}>
                    <Title as="h1" text={t('community.joinTitle')} />
                    <Paragraph>
                        <ExternalLink
                            href="http://www.facebook.com/home.php?sk=group_212302415476791"
                            text="LADZ Facebook"
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://www.gayromeo.com/LadzAmsterdam"
                            text="LADZ Planetromeo Club"
                            icon={true}
                        />
                    </Paragraph>
                    <Title as="h2" text={t('community.alsoTitle')} />
                    <Paragraph>
                        <ExternalLink
                            href="https://www.mixcloud.com/DJRWNL/"
                            text={t('community.rw')}
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://www.trackies.com"
                            text={t('community.trackies')}
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://www.gala-amsterdam.nl"
                            text={t('community.gala')}
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://www.nighttours.com"
                            text={t('community.nighttours')}
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://addiction-berlin.de"
                            text={t('community.addiction')}
                            icon={true}
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
            <section className={css.root}>
                <ContentBlock size="medium">
                    <Title as="h2" text={t('community.otherTitle')} />
                    <Paragraph>
                        <ExternalLink
                            href="http://en.wikipedia.org/wiki/Nike_Air_Max"
                            text={t('community.wiki')}
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://www.gaysite.nl"
                            text={t('community.gaysite')}
                            icon={true}
                        />
                        <br />
                        <ExternalLink
                            href="http://www.patroc.com"
                            text={t('community.patroc')}
                            icon={true}
                        />
                    </Paragraph>
                </ContentBlock>
                <ImageBlock
                    posY="61%"
                    size="small"
                    border={false}
                    src={community2}
                />
            </section>
        </>
    );
};
