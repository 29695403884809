import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './house-rules.module.scss';

import houserules1 from '../../common/pics/ver-8.jpg';

export const HouseRules: React.FC = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>{t('houserules.title')} - LADZ</title>
                <meta
                    name="description"
                    content="The house rules of LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>
            <section className={css.root}>
                <ImageBlock posX="19%" size="small" src={houserules1} />
                <ContentBlock size="medium">
                    <Title as="h1" text={t('houserules.title')} />

                    <ul className={css.list}>
                        <li className={css.listItem}>
                            {t('houserules.item01')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item02')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item03')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item04')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item05')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item06')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item07')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item08')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item09')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item10')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item11')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item12')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item13')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item14')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item15')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item16')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item17')}
                        </li>
                        <li className={css.listItem}>
                            {t('houserules.item18')}
                        </li>
                    </ul>
                </ContentBlock>
            </section>
        </>
    );
};
