import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AgendaList } from '../../features/agenda-list/agenda-list';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './agenda.module.scss';

import agenda1 from '../../common/pics/hor-2.jpg';

export const Agenda = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>Agenda - LADZ</title>
                <meta
                    name="description"
                    content="When is LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>
            <section className={css.root}>
                <ImageBlock posY="69%" size="medium" src={agenda1} />
                <ContentBlock size="small" border={false}>
                    <Title as="h1" text={t('agenda.title')} />
                    <Paragraph>{t('agenda.text1')}</Paragraph>
                    <AgendaList />
                    {/*<Paragraph>{t('agenda.text2')}</Paragraph>*/}
                </ContentBlock>
            </section>
        </>
    );
};
