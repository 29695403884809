import * as React from 'react';
import classNames from 'classnames';

import css from './content-block.module.scss';

export interface ContentBlockProps
    extends React.HTMLAttributes<HTMLDivElement> {
    size: 'small' | 'medium' | 'large';
    border?: boolean;
    children: React.ReactNode;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
    border = 'true',
    size,
    children,
}) => {
    const classes = classNames(
        css.root,
        border && css.border,
        size === 'small' && css.small,
        size === 'medium' && css.medium,
        size === 'large' && css.large,
        size === 'large' && css.noBorder,
    );
    return <article className={classes}>{children}</article>;
};
