import * as React from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import css from './image-block.module.scss';

export interface ImageBlockProps extends React.HTMLAttributes<HTMLDivElement> {
    size: 'small' | 'medium';
    border?: boolean;
    src: string;
    posX?: string;
    posY?: string;
}

export const ImageBlock: React.FC<ImageBlockProps> = ({
    border = 'true',
    size,
    src,
    posX,
    posY,
}) => {
    const classes = classNames(
        css.root,
        border && css.border,
        size === 'small' && css.small,
        size === 'medium' && css.medium,
    );
    return (
        <>
            {!isMobileOnly && (
                <figure
                    style={{
                        backgroundPositionX: `${posX}`,
                        backgroundPositionY: `${posY}`,
                        backgroundImage: `url(${src})`,
                    }}
                    className={classes}
                />
            )}
        </>
    );
};
