import React, { useEffect } from 'react';

export const ScrollIntoView: React.FC = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0 });
        }, 1);
    }, []);

    return null;
};
