import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navigation } from '../../common/elements/navigation/navigation';
import { LanguageSelect } from '../../common/elements/language-select/language-select';

import css from './page-header.module.scss';

import ladzLogo from '../../common/assets/logo-ladz.gif';

export const PageHeader: React.FC = () => {
    return (
        <>
            <header className={css.root}>
                <figure className={css.logo}>
                    <NavLink to="/">
                        <img
                            className={css.image}
                            src={ladzLogo}
                            alt="Logo LADZ"
                        />
                    </NavLink>
                </figure>
                <div className={css.navigation}>
                    <Navigation />
                    <LanguageSelect />
                </div>
            </header>
        </>
    );
};
