import * as React from 'react';
import classNames from 'classnames';

import css from './title.module.scss';

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
    as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    text: string;
    hasMargin?: boolean;
    subHeader?: boolean;
}

export const Title: React.FC<TitleProps> = ({
    as: Tag,
    text,
    hasMargin = false,
    subHeader = false,
}) => {
    const classes = classNames(
        css.root,
        hasMargin && css.hasMargin,
        subHeader && css.subHeader,
    );

    return <Tag className={classes}>{text}</Tag>;
};
