import React from 'react';
import {FooterLeftLinks} from '../../common/elements/footer-left-links/footer-left-links';
import {FooterRightLinks} from '../../common/elements/footer-right-links/footer-right-links';

import css from './page-footer.module.scss';

export const PageFooter: React.FC = () => {
    return (
        <footer className={css.root}>
            <FooterLeftLinks />

            <FooterRightLinks />
        </footer>
    );
};
