import React from 'react';
import { Helmet } from 'react-helmet';
import { isPortraitTablet } from '../../App';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { InternalLink } from '../../common/elements/internal-link/internal-link';
import { ExternalLink } from '../../common/elements/external-link/external-link';
import { AgendaList } from '../../features/agenda-list/agenda-list';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { Ribbon } from '../../common/elements/ribbon/ribbon';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './home.module.scss';

import home1 from '../../common/pics/hor-1.jpg';
import home1mobile from '../../common/pics/hor-1-mobile.jpg';
import home2 from '../../common/pics/ver-1.jpg';

export const Home: React.FC = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>Now it's time for us! LADZ</title>
                <meta
                    name="description"
                    content="LADZ: the only men-only gay dance/cruise party for lads, scallies, gabbers, sneaker- and sportswearboys of the netherlands."
                />
            </Helmet>

            {isMobileOnly ? (
                <section className={`${css.root} ${css.border}`}>
                    <figure
                        className={css.homeImage}
                        style={{ backgroundImage: `url(${home1mobile})` }}
                    />
                </section>
            ) : (
                <>
                    {isPortraitTablet ? (
                        <section className={`${css.root} ${css.border}`}>
                            {/* <Ribbon />*/}
                            <ImageBlock
                                border={false}
                                size="medium"
                                src={home1}
                                posY="60%"
                            />
                        </section>
                    ) : (
                        <section className={`${css.root} ${css.border}`}>
                            <Ribbon />
                            <ImageBlock posY="60%" size="medium" src={home1} />
                            <ImageBlock
                                border={false}
                                size="small"
                                src={home2}
                                posY="bottom"
                            />
                        </section>
                    )}
                </>
            )}

            <section className={css.root}>
                <ContentBlock size="small">
                    <Title as="h1" text={t('home.welcome.title')} />
                    <Paragraph>{t('home.welcome.content')}</Paragraph>

                    <Title as="h2" text={t('home.dresscode.title')} />
                    <Paragraph>
                        {t('home.dresscode.content')}
                        <br />
                        <InternalLink to="/dresscode">
                            {t('home.dresscode.link')}
                        </InternalLink>
                    </Paragraph>
                </ContentBlock>
                <ContentBlock size="small">
                    <Title as="h1" text={t('home.date.title')} />
                    <Paragraph>{t('home.date.content')}</Paragraph>

                    <Title as="h2" text={t('home.dateList.title')} />
                    <AgendaList shortVersion={true} />
                    <Paragraph>
                        <InternalLink to="/agenda">
                            {t('home.dateList.link')}
                        </InternalLink>
                    </Paragraph>
                </ContentBlock>
                <ContentBlock border={false} size="small">
                    <Title as="h1" text={t('home.ladzPics.title')} />
                    <Paragraph>
                        {t('home.ladzPics.content')}{' '}
                        <ExternalLink
                            href={t('home.ladzPics.href')}
                            text={t('home.ladzPics.link')}
                        />
                        {'.'}
                    </Paragraph>

                    <Title as="h1" text={t('home.ladzMusic.title')} />
                    <Paragraph>
                        <ExternalLink
                            href={t('home.ladzMusic.href')}
                            text={t('home.ladzMusic.link')}
                        />{' '}
                        {t('home.ladzMusic.content')}
                    </Paragraph>
                </ContentBlock>
            </section>
        </>
    );
};
