import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import css from './internal-link.module.scss';

export interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    to: string;
    children: React.ReactNode;
}

export const InternalLink: React.FC<LinkProps> = ({ to, children }) => {
    return (
        <RouterLink className={css.root} to={to}>
            {children}
        </RouterLink>
    );
};
