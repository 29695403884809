import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../common/elements/external-link/external-link';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './party-info-location.module.scss';

import party1 from '../../common/pics/ver-2.jpg';
import party2 from '../../common/pics/hor-3.jpg';

export const PartyInfoLocation: React.FC = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>{t('partyInfo.title')} - LADZ</title>
                <meta
                    name="description"
                    content="Everything about the party and the location of LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>

            <section className={`${css.root} ${css.border}`}>
                <ImageBlock size="small" src={party1} />
                <ContentBlock size="medium" border={false}>
                    <Title as="h1" text={t('partyInfo.informationTitle')} />
                    <Paragraph>
                        {t('partyInfo.informationParagraph1')}
                    </Paragraph>
                    <Paragraph>
                        {t('partyInfo.informationParagraph2')}
                    </Paragraph>
                    <Paragraph>
                        {t('partyInfo.informationParagraph3')}
                    </Paragraph>
                    <Paragraph>
                        {t('partyInfo.informationParagraph4')}
                    </Paragraph>
                    <Paragraph>
                        {t('partyInfo.informationParagraph5')}
                        <br />
                        {t('partyInfo.informationParagraph6')}
                    </Paragraph>
                </ContentBlock>
            </section>
            <section className={css.root}>
                <ContentBlock size="small">
                    <Title as="h2" text="LADZ" />
                    <Paragraph>
                        <strong>{t('partyInfo.addressLabel')}</strong>{' '}
                        Kerkstraat 52, Amsterdam
                        <br />
                        <strong>Open:</strong> {t('partyInfo.openingTimes')}
                    </Paragraph>
                    <Paragraph>{t('partyInfo.addressParagraph1')}</Paragraph>
                    <Title as="h3" text={t('partyInfo.publicTransportTitle')} />
                    <Paragraph>
                        {t('partyInfo.publicTransportText')}
                        <br />
                        {t('partyInfo.publicTransportText2')}
                        <br />
                        {t('partyInfo.publicTransportText3')}
                        <br />
                        {t('partyInfo.publicTransportText4')}{' '}
                        <ExternalLink
                            text="www.gvb.nl"
                            href="http://www.gvb.nl"
                        />
                    </Paragraph>
                    <Title as="h3" text={t('partyInfo.carTitle')} />
                    <Paragraph>{t('partyInfo.carText')}</Paragraph>

                    <Paragraph>
                        {t('partyInfo.mapsText')}{' '}
                        <ExternalLink
                            href={t('partyInfo.mapsUrl')}
                            text="Google Maps"
                        />
                    </Paragraph>
                </ContentBlock>

                <ImageBlock
                    posY="63%"
                    size="medium"
                    border={false}
                    src={party2}
                />
            </section>
        </>
    );
};
