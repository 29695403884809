import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../common/elements/external-link/external-link';
import { InternalLink } from '../../common/elements/internal-link/internal-link';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './ladz-november-2021.module.scss';

import november from '../../common/pics/ver-11.jpg';

export const LadzNovember2021 = () => {
    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>27 November 2021 edition Cancelled - LADZ</title>
                <meta
                    name="description"
                    content="More information about the November 2021 edition of LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>
            <section className={css.root}>
                <ImageBlock posY="68%" size="small" src={november} />
                <ContentBlock size="medium">
                    <Title hasMargin as="h1" text="LADZ are BACK!" />
                    <Paragraph>
                        After more than 1,5 year of absence (except for our
                        Winter White Live Stream) we are finally back at the
                        renovated Club ChUrch!
                    </Paragraph>

                    <Paragraph>
                        - You need to show a valid QR code (via{' '}
                        <ExternalLink
                            href="https://coronacheck.nl/"
                            text="Coronacheck App"
                        />
                        ) along with your ID to enter.
                        <br /> - If you’re not vaccinated you can test for free
                        at{' '}
                        <ExternalLink
                            href="http://testenvoortoegang.org/"
                            text="testenvoortoegang.nl"
                        />{' '}
                        (also for international visitors)
                        <br />- Most European QR codes are working in The
                        Netherlands. Codes from outside the EU work depending on
                        the country of issuance. *
                    </Paragraph>
                    <Paragraph>
                        LADZ must unfortunately stop at midnight.
                    </Paragraph>
                    <Paragraph>
                        * Want to check if your code is working? Download
                        "coronacheck scanner" on a different device and scan
                        yourself.
                    </Paragraph>
                    <Paragraph>
                        While you're on our website anyway, also check out our{' '}
                        <InternalLink to="/dresscode">dresscode</InternalLink>,
                        which will be enforced strictly.
                        <br />A perfect reason to spend some money at the local
                        stores!
                    </Paragraph>

                    <Paragraph>
                        <strong>Doors open at 17.00</strong> <br />
                        we expect lot's of LADZ to join us, so make sure you're
                        on time!
                    </Paragraph>

                    <Paragraph>
                        DJ's Be-rik and DJ RW will create the perfect atmosphere
                        and our LADZ volunteers are all excited to make this one
                        of the best LADZ editions ever!
                    </Paragraph>

                    <Paragraph>Please be in time and be prepared.</Paragraph>

                    <Paragraph>
                        See you all again soon!
                        <br />
                        We’ve missed you all!
                    </Paragraph>

                    <Paragraph>
                        <ExternalLink
                            href="http://www.facebook.com/home.php?sk=group_212302415476791"
                            text="Check also the LADZ Facebook group for more information."
                            icon={true}
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
        </>
    );
};
