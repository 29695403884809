import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './dresscode.module.scss';

import dresscode1 from '../../common/pics/dresscode.jpg';
import dresscode2 from '../../common/pics/hor-4.jpg';

export const Dresscode = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>Dresscode - LADZ</title>
                <meta
                    name="description"
                    content="The dresscode of LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>

            <section className={`${css.root} ${css.border}`}>
                <ImageBlock size="small" src={dresscode1} />
                <ContentBlock size="medium" border={false}>
                    <Title as="h1" text={t('dresscode.pageTitle')} />

                    <Paragraph>
                        {t('dresscode.wearPar1a')}
                        <br />
                        {t('dresscode.wearPar1b')}
                    </Paragraph>
                    <Paragraph>
                        {t('dresscode.wearPar2a')}
                        <br />
                        {t('dresscode.wearPar2b')}
                    </Paragraph>
                    <Paragraph>{t('dresscode.wearPar3')}</Paragraph>

                    <Title as="h2" text={t('dresscode.wearTitle')} />
                    <Paragraph>
                        {t('dresscode.wearLista')}
                        <br />
                        {t('dresscode.wearListb')}
                    </Paragraph>
                    <Paragraph>{t('dresscode.wearLast')}</Paragraph>
                </ContentBlock>
            </section>
            <section className={css.root}>
                <ContentBlock size="small">
                    <Title as="h1" text={t('dresscode.wearNotTitle')} />
                    <Paragraph>
                        {t('dresscode.wearNotItem1')}
                        <br />
                        {t('dresscode.wearNotItem2')}
                        <br />
                        {t('dresscode.wearNotItem3')}
                        <br />
                        {t('dresscode.wearNotItem4')}
                        <br />
                        {t('dresscode.wearNotItem5')}
                        <br />
                        {t('dresscode.wearNotItem6')}
                    </Paragraph>
                    <Paragraph>{t('dresscode.wearNotPar1')}</Paragraph>
                    <Paragraph>{t('dresscode.wearNotPar2')}</Paragraph>
                </ContentBlock>
                <ImageBlock
                    posY="71%"
                    size="medium"
                    border={false}
                    src={dresscode2}
                />
            </section>
        </>
    );
};
