import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './footer-left-links.module.scss';

export const FooterLeftLinks = () => {
    const { t } = useTranslation('common');

    return (
        <ul className={css.root}>
            <li className={css.item}>{t('footer.copyright')}</li>
            <li className={css.item}>
                <NavLink
                    activeClassName={css.active}
                    className={css.link}
                    to="/disclaimer"
                >
                    {t('footer.disclaimer')}
                </NavLink>
            </li>
            <li className={css.item}>
                <NavLink
                    activeClassName={css.active}
                    className={css.link}
                    to="/house-rules"
                >
                    {t('footer.houserules')}
                </NavLink>
            </li>
        </ul>
    );
};
