import * as React from 'react';

import css from './external-link.module.scss';

export interface ExternalLinkProps
    extends React.HTMLAttributes<HTMLAnchorElement> {
    href: string;
    text: string;
    icon?: boolean;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
    icon = false,
    href,
    text,
}) => {
    return (
        <a
            href={href}
            className={`
                ${css.root}
                ${icon && css.icon}
            `}
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </a>
    );
};
