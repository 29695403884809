import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from '../external-link/external-link';

import css from './footer-right-links.module.scss';

export const FooterRightLinks = () => {
    const { t } = useTranslation('common');

    return (
        <ul className={css.root}>
            {/*<li className={css.item}>{t('footer.design')}</li>*/}
            <li className={css.item}>
                {t('footer.photography')}{' '}
                <ExternalLink
                    href="https://www.antondebruinphotography.eu/"
                    text="Anton de Bruin"
                    className={css.link}
                />{' '}
                &{' '}
                <ExternalLink
                    href="http://www.renezuiderveld.com/"
                    text="Rene Zuiderveld"
                    className={css.link}
                />
            </li>
        </ul>
    );
};
