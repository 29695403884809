import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import css from './language-select.module.scss';

import flagEn from '../../assets/flag-en.svg';
import flagNL from '../../assets/flag-nl.svg';

export const LanguageSelect = () => {
    const { i18n } = useTranslation('common');
    return (
        <article className={css.root}>
            <button
                className={`${css.button} ${
                    i18next.language === 'en' && css.active
                }`}
                onClick={() => i18n.changeLanguage('en')}
            >
                <img className={css.icon} src={flagEn} alt="English" />
            </button>
            <button
                className={`${css.button} ${
                    i18next.language === 'nl' && css.active
                }`}
                onClick={() => i18n.changeLanguage('nl')}
            >
                <img className={css.icon} src={flagNL} alt="Nederlands" />
            </button>
        </article>
    );
};
