import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../common/elements/external-link/external-link';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from './contact.module.scss';

import contact1 from '../../common/pics/hor-5.jpg';

export const Contact = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>Contact - LADZ</title>
                <meta
                    name="description"
                    content="How to contact LADZ, the gay dance/cruise party for lads, scallies, sportswear- and sneakerboys!"
                />
            </Helmet>
            <section className={css.root}>
                <ImageBlock posY="85%" size="medium" src={contact1} />
                <ContentBlock size="small" border={false}>
                    <Title as="h1" text={t('contact.title')} />
                    <Paragraph>{t('contact.text')}</Paragraph>
                    <Paragraph>
                        <ExternalLink
                            href="mailto:info@ladz.nl"
                            text="info@ladz.nl"
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
        </>
    );
};
