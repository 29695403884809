import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_nl from './translations/nl/common.json';
import common_en from './translations/en/common.json';
import './index.scss';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    debug: false,
    resources: {
        en: {
            common: common_en,
        },
        nl: {
            common: common_nl,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
