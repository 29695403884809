import * as React from 'react';

import css from './paragraph.module.scss';

export interface ParagraphProps
    extends React.HTMLAttributes<HTMLParagraphElement> {
    children: React.ReactNode;
    noMargin?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({
    noMargin = false,
    children,
}) => {
    return (
        <p className={`${noMargin && css.noMargin} ${css.root}`}>{children}</p>
    );
};
