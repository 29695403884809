import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../common/elements/title/title';

import css from './agenda-list.module.scss';

export interface AgendaListProps {
    shortVersion?: boolean;
    ribbonVersion?: boolean;
}

export const AgendaList: React.FC<AgendaListProps> = ({
    ribbonVersion = false,
    shortVersion,
}) => {
    const { t } = useTranslation('common');
    const agendaList = [

        { date: t('agenda.dateSeptember') },
        { date: t('agenda.dateNovember') },
        { date: t('agenda.dateJanuary') },
        { date: t('agenda.dateMarch') },
        { date: t('agenda.dateMay') },
        { date: t('agenda.dateJuly') },
    ];

    return (
        <>
            {ribbonVersion ? (
                agendaList
                    .slice(0, 1)
                    .map((agendaList, index) => (
                        <Title as="h5" key={index} text={agendaList.date} />
                    ))
            ) : (
                <ol className={css.root}>
                    {shortVersion
                        ? agendaList.slice(0, 3).map((agendaList, index) => (
                              <li className={css.listItem} key={index}>
                                  {agendaList.date}
                                  {index === 1 && <> - {t('agenda.tagline')}</>}
                              </li>
                          ))
                        : agendaList.map((agendaList, index) => (
                              <li className={css.listItem} key={index}>
                                  {agendaList.date}
                                  {index === 1 && <> - {t('agenda.tagline')}</>}
                              </li>
                          ))}
                </ol>
            )}
        </>
    );
};
