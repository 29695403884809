import * as React from 'react';
import { AgendaList } from '../../../features/agenda-list/agenda-list';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../paragraph/paragraph';

import css from './ribbon.module.scss';

export const Ribbon: React.FC = () => {
    const { t } = useTranslation('common');

    return (
        <article className={css.root}>
            <Paragraph className={css.text} noMargin>
                {t('home.ribbon')}
            </Paragraph>
            <AgendaList ribbonVersion />
        </article>
    );
};
