import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home } from './pages/home/home';
import { PartyInfoLocation } from './pages/party-info-location/party-info-location';
import { Agenda } from './pages/agenda/agenda';
import { Dresscode } from './pages/dresscode/dresscode';
import { Community } from './pages/community/community';
import { Contact } from './pages/contact/contact';
import { Disclaimer } from './pages/disclaimer/disclaimer';
import { HouseRules } from './pages/house-rules/house-rules';
import { LadzNovember2021 } from './pages/ladz-november-2021/ladz-november-2021';

import { Error } from './pages/error/error';

const Routes = () => (
    <Switch>
        <Route path="/" component={Home} exact />
        <Route
            path="/party-info-location"
            component={PartyInfoLocation}
            exact
        />
        <Route path="/agenda" component={Agenda} exact />
        <Route path="/dresscode" component={Dresscode} exact />
        <Route path="/community" component={Community} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/disclaimer" component={Disclaimer} exact />
        <Route path="/house-rules" component={HouseRules} exact />
        <Route path="/ladz-november-2021" component={LadzNovember2021} exact />

        <Route component={Error} />
    </Switch>
);

export default Routes;
