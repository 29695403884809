import React from 'react';
import { Helmet } from 'react-helmet';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { ImageBlock } from '../../common/elements/image-block/image-block';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { isMobileOnly } from 'react-device-detect';
import { ScrollIntoView } from '../../common/scripts/scroll';

import css from '../agenda/agenda.module.scss';

import error1 from '../../common/pics/ver-10.jpg';

export const Error = () => {
    return (
        <>
            <ScrollIntoView />
            <Helmet>
                <title>404 page not found - LADZ</title>
            </Helmet>
            <section className={css.root}>
                <ContentBlock size="medium">
                    <Title as="h1" text="404 - page not found" />

                    <Paragraph>
                        {!isMobileOnly && (
                            <>
                                But now you are here.... on the right the guys
                                who started the first LADZ back in April 2008!
                                😉
                            </>
                        )}
                    </Paragraph>
                </ContentBlock>
                <ImageBlock
                    border={false}
                    posY="30%"
                    size="small"
                    src={error1}
                />
            </section>
        </>
    );
};
